.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sg-header {
  background-color: #fff;
  border-top: 1px solid #d9dbe9;
  border-bottom: 1px solid #d9dbe9;
}

.page-header {
  color: rgba(0, 0, 0, 0.8);
}

.sign-out-button {
  border: 1px solid #d9dbe9;
  color: rgba(0, 0, 0, 0.8);
  background-color: #6b19e5;
  color: white;
}

.sign-out-button:hover {
  color: #542189;
}

.summary-report-section-header {
  background-color: beige;
}

.summary-report-row-with-header {
  background-color: azure;
}

#exclusionsReportTable .trData td {
  border-bottom: solid 1px lightgrey;
}

table {
  color: #262338;
  font-weight: 500;
  table-layout: fixed;
}

table th {
  border-color: black;
}

table td {
  border: solid 1px black;
}

table .index {
  width: 40px;
  text-align: right;
  padding-right: 10px;
}

.section-header {
  background-color: #392f75;
  color: white;
  font-weight: bold;
  padding: 10px;
}

.table-header {
  background-color: #dce6f7;
  color: #262338;
}

.form-select {
  color: #262338;
}

.form-label {
  font-weight: normal;
  color: #6e7191;
}

.table-title {
  background-color: #392f75;
  color: white;
}

.table-section-title {
  background-color: #d0c5d2;
  font-weight: bold;
  color: #262338;
}

tr.table-section-title > th {
  color: #262338 !important;
}

#SummaryReportSection {
  background-color: white;
}

#SummaryReportSection > tbody > tr > th[scope='row'] {
  font-weight: 400;
}

#runBfButton {
  background-color: #742c84;
  color: white;
}
